import Modal from 'react-bootstrap/Modal'
import { useEffect } from 'react'

type CancelModalProps = {
  show: boolean
  title: string
  body: string
  onAccept: () => void
  onReject: () => void
}

export const CancelModal = ({
  show,
  title,
  body,
  onAccept,
  onReject,
}: CancelModalProps) => {
  function handleKeyDown(event: KeyboardEvent) {
    if (['Escape', 'Esc'].includes(event.key)) {
      onReject()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  })

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button type="button" className="close" onClick={() => onReject()}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: body }} />
      <Modal.Footer>
        <div className="p-2">
          <button
            className="btn btn-light btn-block"
            onClick={() => onAccept()}>
            Yes
          </button>
        </div>
        <div className="p-2">
          <button className="btn btn-primary light" onClick={() => onReject()}>
            No
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

type ConfirmationModalProps = {
  show: boolean
  title: string
  body: string
  onAccept: () => void
  onReject: () => void
}

export const ConfirmationModal = ({
  show,
  title,
  body,
  onAccept,
  onReject,
}: ConfirmationModalProps) => {
  function handleKeyDown(event: KeyboardEvent) {
    if (['Escape', 'Esc'].includes(event.key)) {
      onReject()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  })

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button type="button" className="close" onClick={() => onReject()}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: body }} />
      <Modal.Footer>
        <div className="p-2">
          <button
            className="btn btn-light btn-block"
            onClick={() => onReject()}>
            No
          </button>
        </div>
        <div className="p-2">
          <button className="btn btn-primary light" onClick={() => onAccept()}>
            Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

type InfoModalProps = {
  show: boolean
  title: string
  body: string
  onDone: () => void
}

export const InfoModal = ({ show, title, body, onDone }: InfoModalProps) => {
  function handleKeyDown(event: KeyboardEvent) {
    if (['Escape', 'Esc'].includes(event.key)) {
      onDone()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  })

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button type="button" className="close" onClick={() => onDone()}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <div className="p-2"></div>
        <div className="p-2">
          <button className="btn btn-primary light" onClick={() => onDone()}>
            Okay
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
