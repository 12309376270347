import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useContext } from 'react'
import { SubscriptionType, PlanCode } from '../../types/types'
import { useRouter } from 'next/router'

import { createPendingChange } from '../../services/SubscriptionApiClient'
import { ThreeDSecureActionModal } from '../Payment/ThreeDSecureActionModal'

type PropTypes = {
  show: boolean
  subscription: SubscriptionType
  onAccept: () => void
  onReject: () => void
}

export const ChurnOfferModalLate = ({
  show,
  onAccept,
  onReject,
  subscription,
}: PropTypes) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [threeDSecureActionToken, setThreeDSecureActionToken] = useState('')
  const [threeDSecureResultToken, setThreeDSecureResultToken] = useState('')

  const router = useRouter()

  useEffect(() => {
    setErrorMessage(undefined)
  }, [show])

  useEffect(() => {
    const handleThreeDSecure = async () => {
      if (threeDSecureResultToken) {
        await createPendingChange()
        setThreeDSecureResultToken('')
      }
      handleThreeDSecure()
    }
  }, [threeDSecureResultToken])

  const changePlan = async () => {
    const billingInfo = threeDSecureResultToken
      ? { threeDSecureResultTokenId: threeDSecureResultToken }
      : {}
    try {
      const resp = await createPendingChange(subscription.id, {
        planCode: PlanCode.ANNUAL,
        couponCode: 'annual-retention-offer',
        timeframe: 'now',
        billingInfo: billingInfo,
      })
      if (resp.error && resp.threedsActionRequired && resp.threedsToken) {
        setThreeDSecureActionToken(resp.threedsToken)
      }
      onAccept()
    } catch (err) {
      console.log(err)
      setErrorMessage(
        'There was an error changing your plan. Please contact support if the problem persists.',
      )
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (['Escape', 'Esc'].includes(event.key)) {
      onReject()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  })

  return (
    <>
      <Modal show={show} centered>
        <Modal.Header>
          <Modal.Title>Don’t unplug!</Modal.Title>
          <button type="button" className="close" onClick={() => onReject()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <span>
            Make more music, <b>50%</b> off the Reason+ Annual Plan.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <div className="p-2">
            <button
              className="btn btn-light btn-block"
              onClick={() => onReject()}>
              Cancel anyway
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-primary light"
              onClick={() => changePlan()}>
              Pay Now & Stay On
            </button>
          </div>
        </Modal.Footer>
        <Modal.Footer>
          <span style={{ fontSize: '0.65em' }}>
            By accepting this offer, you will switch to the Reason Studios
            Annual plan and be charged <b>$99</b> immediately. When the offer
            term ends in one year, you will automatically renew the annual
            subscription at standard price. This is a one time, one year offer.
          </span>
        </Modal.Footer>
        {errorMessage !== '' && (
          <div className="mb-20 text-center color-raspberry">
            {errorMessage}
          </div>
        )}
      </Modal>
      <ThreeDSecureActionModal
        actionTokenId={threeDSecureActionToken}
        onResultToken={setThreeDSecureResultToken}
        onClose={() => setThreeDSecureActionToken('')}
      />
    </>
  )
}
