import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useContext } from 'react'
import { CouponCodeInput } from '../Coupons/CouponCodeInput'
import {
  SubscriptionType,
  Coupon,
  CouponState,
  PlanCode,
} from '../../types/types'
import { CouponContext } from '../context'
import { useRouter } from 'next/router'

import {
  getCoupon,
  createPendingChange,
} from '../../services/SubscriptionApiClient'
import { ThreeDSecureActionModal } from '../Payment/ThreeDSecureActionModal'

type PropTypes = {
  show: boolean
  title: string
  body: string
  subscription: SubscriptionType
  newPlan: string
  onAccept: () => void
  onReject: () => void
}

export const SubscriptionChangeModal = ({
  show,
  title,
  onAccept,
  onReject,
  subscription,
  body,
  newPlan,
}: PropTypes) => {
  const [couponCode, setCouponCode] = useState<string | undefined>()
  const [couponDisplayText, setCouponDisplayText] = useState<
    string | undefined
  >()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [showCoupon, setShowCoupon] = useState(false)
  const { coupons, updateCoupons } = useContext(CouponContext)
  const [threeDSecureActionToken, setThreeDSecureActionToken] = useState('')
  const [threeDSecureResultToken, setThreeDSecureResultToken] = useState('')

  const router = useRouter()

  const hasActiveCoupon = coupons.some((c) => c.state === CouponState.ACTIVE)

  const allowCoupon = newPlan !== PlanCode.MONTHLY

  useEffect(() => {
    setCouponDisplayText(undefined)
    setErrorMessage(undefined)
    setCouponCode(undefined)
  }, [show])

  useEffect(() => {
    const handleThreeDSecure = async () => {
      if (threeDSecureResultToken) {
        await createPendingChange()
        setThreeDSecureResultToken('')
      }
      handleThreeDSecure()
    }
  }, [threeDSecureResultToken])

  const changePlan = async () => {
    const billingInfo = threeDSecureResultToken
      ? { threeDSecureResultTokenId: threeDSecureResultToken }
      : {}
    try {
      const resp = await createPendingChange(subscription.id, {
        planCode: newPlan,
        couponCode,
        timeframe: couponCode ? 'now' : 'renewal',
        billingInfo: billingInfo,
      })
      if (resp.error && resp.threedsActionRequired && resp.threedsToken) {
        setThreeDSecureActionToken(resp.threedsToken)
      }
      updateCoupons()
      onAccept()
      if (newPlan === PlanCode.ARCADE_BUNDLE) {
        router.push('/arcade')
      }
    } catch (err) {
      console.log(err)
      setErrorMessage(
        'There was an error changing your plan. Please contact support if the problem persists.',
      )
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (['Escape', 'Esc'].includes(event.key)) {
      onReject()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  })

  const testIfValid = async (code: string) => {
    try {
      setCouponDisplayText(undefined)
      const coupon = await getCoupon(code)
      if (
        coupon.state === 'redeemable' &&
        (coupon.appliesToAllPlans || coupon.planCodes.includes(newPlan))
      ) {
        setCouponDisplayText(coupon.displayText)
        return true
      }
      return false
    } catch (err) {
      return false
    }
  }

  return (
    <>
      <Modal show={show} centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <button type="button" className="close" onClick={() => onReject()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-20">{body}</div>
          {allowCoupon && (
            <button
              className="btn btn-link d-block mx-auto mb-20"
              onClick={() => setShowCoupon(!showCoupon)}>
              Do you have a coupon?
            </button>
          )}
          {showCoupon && (
            <div>
              <CouponCodeInput
                testIfValid={testIfValid}
                onValid={setCouponCode}
              />
            </div>
          )}
          {couponDisplayText && (
            <div className="text-center">{couponDisplayText}</div>
          )}
          {couponDisplayText && hasActiveCoupon && (
            <div className="alert alert-warning d-block mx-auto text-center text-small mt-10">
              Applying this coupon will override the currently active coupon
            </div>
          )}
          {errorMessage !== '' && (
            <div className="mb-20 text-center color-raspberry">
              {errorMessage}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="p-2">
            <button
              className="btn btn-light btn-block"
              onClick={() => onReject()}>
              Cancel
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-primary light"
              onClick={() => changePlan()}>
              Change plan
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <ThreeDSecureActionModal
        actionTokenId={threeDSecureActionToken}
        onResultToken={setThreeDSecureResultToken}
        onClose={() => setThreeDSecureActionToken('')}
      />
    </>
  )
}
