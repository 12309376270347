import { ThreeDSecureAction } from '@recurly/react-recurly'
import { RecurlyError, TokenPayload } from '@recurly/recurly-js'
import { Modal } from 'react-bootstrap'
import { InfoModal } from '../Modal'
import { useEffect, useState } from 'react'

interface propsTypes {
  actionTokenId?: string
  onResultToken: (resultToken: string) => void
  onClose: () => void
}

export const ThreeDSecureActionModal = (props: propsTypes) => {
  const handleTokenPayload = (tokenPayload: TokenPayload) => {
    if (tokenPayload.type === 'three_d_secure_action_result') {
      props.onResultToken(tokenPayload.id)
    }
  }

  const [error, setError] = useState<RecurlyError | undefined>(undefined)

  useEffect(() => {
    const showError = async (e: RecurlyError) => {
      props.onClose()
    }
    if (error) {
      console.error(error)
      showError(error)
    }
  }, [error])

  return (
    <div>
      <Modal show={!!props.actionTokenId} centered>
        <Modal.Header>
          <Modal.Title>Authorize Payment</Modal.Title>
          <button type="button" className="close" onClick={props.onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h6>Further authorization required:</h6>
            <div
              className="d-flex justify-content-center pt-20 mb-15"
              style={{ minHeight: '400px' }}>
              <ThreeDSecureAction
                actionTokenId={props.actionTokenId}
                onToken={handleTokenPayload}
                onError={setError}></ThreeDSecureAction>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={!!error} centered>
        <Modal.Header className="pb-0 mb-0">
          <Modal.Title>Authorization error</Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => setError(undefined)}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body className="mt-0 pt-1">
          <div>
            <p className="text-danger">
              {error?.message}
              <span className="text-xs">
                <br />
                (code: {error?.code})
              </span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
