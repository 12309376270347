import { useCallback, useState } from 'react'

import debounce from 'lodash.debounce'

interface PropType {
  testIfValid: (code: string) => Promise<boolean>
  onValid: (code: string) => void
}

export const CouponCodeInput = ({ testIfValid, onValid }: PropType) => {
  const [loading, setLoading] = useState(false)
  const [couponValid, setCouponValid] = useState(false)
  const [couponInput, setCouponInput] = useState('')

  const debouncedSave = useCallback(
    debounce(
      (code: string) => {
        testIfValid(code).then((isValid) => {
          setLoading(false)
          setCouponValid(isValid)
          if (isValid) {
            onValid(code)
          }
        })
      },
      process.env.NODE_ENV === 'test' ? 0 : 500,
    ),
    [],
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value.trim()
    setCouponInput(code)
    if (code.length > 0) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    debouncedSave(code)
  }

  return (
    <div className="w-100 position-relative">
      <input
        className="form-control text-center mb-20"
        type="text"
        placeholder="Enter coupon code"
        onChange={(e) => handleChange(e)}
        value={couponInput}
      />
      <span
        className="position-absolute"
        style={{ top: '12px', right: '25px' }}>
        {loading ? (
          <i className="fa fa-circle-o-notch fa-spin greyed" />
        ) : (
          <>
            {couponInput === '' ? (
              ''
            ) : (
              <>
                {couponValid ? (
                  <i
                    className="fa fa-check"
                    style={{ color: 'var(--success)' }}
                  />
                ) : (
                  <i
                    className="fa fa-exclamation-circle"
                    style={{ color: 'var(--danger)' }}
                  />
                )}
              </>
            )}
          </>
        )}
      </span>
    </div>
  )
}
