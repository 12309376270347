import { RecurlyError } from '@recurly/recurly-js'
import {
  BaseSubscriptionType,
  SubscriptionStatus,
  SubscriptionType,
} from '../../types/types'
import { formatDate, formatPlanName } from '../../utils'
import { isSubscriptionType } from '../Cards/SubscriptionCard'

export function getSubscriptionInfoAlert(
  subscription: BaseSubscriptionType | SubscriptionType,
): JSX.Element {
  if (isSubscriptionType(subscription)) {
    const expDateJsx = <strong>{formatDate(subscription.expiryDate)}</strong>

    switch (true) {
      case !!subscription.pendingChange:
        return (
          <div className="alert alert-warning">
            Your subscription plan will change to{' '}
            {formatPlanName(subscription.pendingChange!.planName)} on{' '}
            {expDateJsx}.
          </div>
        )

      case subscription.state === SubscriptionStatus.CANCELED:
        return (
          <div className="alert alert-warning">
            Your subscription will be canceled on {expDateJsx}.
          </div>
        )

      case subscription.state === SubscriptionStatus.EXPIRED:
        return (
          <div className="alert alert-warning">
            Your subscription expires on {expDateJsx}.
          </div>
        )
    }
  }

  return <></>
}

export function formatRecurlyError(err: RecurlyError): JSX.Element {
  const keyMap = {
    first_name: 'First name',
    last_name: 'Last name',
    address1: 'Address',
    city: 'City',
    postal_code: 'Postal code',
    country: 'Country',
    state: 'State',
    number: 'Card number',
    month: 'Card month',
    year: 'Card year',
    cvv: 'CVV',
  }

  function getDetails(key: string): string {
    if (!err.details) return 'is invalid'
    const f = err.details!.find((e) => e.field === key)
    return f ? f.messages[0] : 'is invalid'
  }

  let buf: JSX.Element[] = []

  for (const [key, value] of Object.entries(keyMap)) {
    if (err.fields?.includes(key)) {
      buf.push(
        <p key={key}>
          {value} {getDetails(key)}
        </p>,
      )
    }
  }
  return <>{buf}</>
}
