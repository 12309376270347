import styles from '../../styles/Card.module.scss'
import { useState, useContext } from 'react'
import {
  BaseSubscriptionType,
  SubscriptionType,
  PlanCode,
  RecurlyAccount,
  isError,
} from '../../types/types'
import { formatCurrency, formatPlanName } from '../../utils'
import { SubscriptionChangeModal } from '../Subscription/SubscriptionChangeModal'
import { ChurnOfferModalEarly } from '../Subscription/ChurnOfferModalEarly'
import { ChurnOfferModalLate } from '../Subscription/ChurnOfferModalLate'
import { CancelModal, ConfirmationModal, InfoModal } from '../Modal'
import { applyAntichurnCoupon } from '../../services/SubscriptionApiClient'
import { RecurlyAccountContext } from '../../components/context'

type propTypes = {
  subscription: BaseSubscriptionType | SubscriptionType
  currentSubscription: SubscriptionType
  cancelOrReactivateSubscription: () => void
  reloadSubscription: Function
  cancelChange: () => void
  hasPendingChange?: boolean
  currency: string
}

export function isSubscriptionType(
  x: BaseSubscriptionType | SubscriptionType,
): x is SubscriptionType {
  return (x as SubscriptionType).state !== undefined
}

export default function SubscriptionCard({
  subscription,
  cancelOrReactivateSubscription,
  cancelChange,
  hasPendingChange = false,
  currentSubscription,
  reloadSubscription,
  currency,
}: propTypes) {
  const { account } = useContext(RecurlyAccountContext)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showChange, setShowChange] = useState(false)
  if (!subscription) return <></>

  // If subscription is of type SubscriptionType (ie has a state property) we assume it's the current subscription
  const isCurrentUserSubscription = isSubscriptionType(subscription)
  let isTrial = false
  let isCanceled = false
  let churnEarly = false
  let churnLate = false
  if (
    account &&
    !isError(account) &&
    (account as RecurlyAccount).hasAntichurned == false
  ) {
    churnEarly = account.canAntichurnEarly || false
    churnLate = account.canAntichurnLate || false
  }

  if (isCurrentUserSubscription && isSubscriptionType(subscription)) {
    isTrial = subscription.isTrial
    isCanceled = subscription.state === 'canceled'
  }
  // If this is false we'll assume annual
  const isMonthly = subscription.planCode === PlanCode.MONTHLY

  const makeConfirmationModal = () => {
    if (isCurrentUserSubscription) {
      if (!isCanceled) {
        if (churnEarly) {
          let discountedPrice =
            Math.trunc(
              Number((subscription as SubscriptionType).price) * 0.35 * 100,
            ) / 100
          return (
            <ChurnOfferModalEarly
              show={showConfirmation}
              onAccept={() => {
                setShowConfirmation(false)
                applyAntichurnCoupon().then(() => location.reload())
              }}
              title={'Don’t unplug!'}
              body={'Make more music, <b>65%</b> off for 3 months.'}
              disclaimer={
                '<span>You will be charged <b>' +
                formatCurrency(discountedPrice, currency) +
                '</b> per month for <b>3 months</b>. Afterward, your subscription will automatically renew at the standard price. This discount will be applied immediately but will not apply to any outstanding invoice.</span>'
              }
              acceptText="Accept & Stay on"
              rejectText="Cancel anyway"
              onReject={() => {
                setShowConfirmation(false)
                cancelOrReactivateSubscription()
              }}
              onClose={() => {
                setShowConfirmation(false)
              }}
            />
          )
        } else if (churnLate) {
          return (
            <ChurnOfferModalLate
              show={showConfirmation}
              onAccept={() => {
                reloadSubscription().then(() => setShowChange(false))
              }}
              onReject={() => {
                setShowConfirmation(false)
                cancelOrReactivateSubscription()
              }}
              subscription={currentSubscription}
            />
          )
        }
      }
      return isCanceled ? (
        <ConfirmationModal
          show={showConfirmation}
          onAccept={() => {
            setShowConfirmation(false)
            cancelOrReactivateSubscription()
          }}
          title={'Reactivate subscription'}
          body={'Are you sure that you want to reactivate your subscription?'}
          onReject={() => setShowConfirmation(false)}
        />
      ) : (
        <CancelModal
          show={showConfirmation}
          onAccept={() => {
            setShowConfirmation(false)
            cancelOrReactivateSubscription()
          }}
          title={'Cancel subscription'}
          body={'Are you sure that you want to cancel your subscription?'}
          onReject={() => setShowConfirmation(false)}
        />
      )
    } else {
      if (hasPendingChange) {
        return (
          <ConfirmationModal
            show={showConfirmation}
            onAccept={() => {
              setShowConfirmation(false)
              cancelChange()
            }}
            title={'Cancel change'}
            body={'Are you sure that you want to cancel your pending change?'}
            onReject={() => setShowConfirmation(false)}
          />
        )
      } else {
        let textBody = `This will change your subscription to ${
          isMonthly ? 'a monthly' : 'an annual'
        } subscription.`
        if (
          currentSubscription.planCode === PlanCode.MONTHLY &&
          subscription.planCode === PlanCode.ARCADE_BUNDLE
        ) {
          textBody = `This will change your subscription to the Reason+ Arcade bundle.
            You will be charged immediately and the price will be adjusted to take into account what you have already paid for this month.`
        }
        if (
          currentSubscription.planCode === PlanCode.ANNUAL &&
          subscription.planCode === PlanCode.ARCADE_BUNDLE
        ) {
          return (
            <InfoModal
              show={showChange}
              title={'Can not change to this plan'}
              body={`Unfortunately you can not change to this plan while you have an annual subscription active.
                To change to the Reason+ Arcade offer you have to cancel your current annual subscription,
                wait for it to expire and then subscribe to the bundle`}
              onDone={() => setShowChange(false)}
            />
          )
        }

        return (
          <SubscriptionChangeModal
            show={showChange}
            body={textBody}
            onAccept={() => {
              reloadSubscription().then(() => setShowChange(false))
            }}
            title={'Change subscription'}
            subscription={currentSubscription}
            newPlan={subscription.planCode}
            onReject={() => setShowChange(false)}
          />
        )
      }
    }
  }

  let bodyText = <>Cancel anytime!</>

  if (subscription.planCode === PlanCode.ANNUAL) {
    bodyText = <b className="color-new-orange">Save 17%!</b>
  }

  if (subscription.planCode === PlanCode.ARCADE_BUNDLE) {
    bodyText = <>The ultimate music making bundle</>
  }

  const changeAllowed = !(
    currentSubscription.planCode === PlanCode.ANNUAL &&
    subscription.planCode === PlanCode.ARCADE_BUNDLE
  )

  const priceString = `${formatCurrency(subscription.price, currency)}${
    isMonthly ? ' / month' : ' / year'
  }`

  return (
    <div
      className={`d-flex justify-content-end flex-column p-5 mx-auto mx-md-5 rounded ${
        styles.subscriptionCardWrapper
      } ${
        subscription?.planCode && styles[subscription?.planCode]
          ? styles[subscription?.planCode]
          : ''
      } ${isCurrentUserSubscription ? styles.currentSubscription : ''}`}>
      {makeConfirmationModal()}
      {isCurrentUserSubscription && (
        <div className="text-uppercase text-center pt-5 pb-10">
          <strong className={`${styles.subscriptionCardLegend}`}>
            Current plan
          </strong>
        </div>
      )}
      <div
        className={`d-flex flex-column rounded text-center justify-content-center ${styles.subscriptionCard}`}>
        <div
          className={`d-flex align-items-center rounded-top justify-content-center ${styles.subscriptionCardHead}`}>
          <h4>
            {
              // Workaround for displaying the picture
              subscription?.planCode === PlanCode.ARCADE_BUNDLE
                ? ''
                : formatPlanName(subscription?.planName)
            }
          </h4>
        </div>
        <div
          className={`container p-20 ${styles.subscriptionCardBody} position-relative`}>
          <div className="d-flex justify-content-center mb-5">
            <h5>{priceString}</h5>
          </div>
          <div className="d-flex justify-content-center mb-20 font-inter">
            {bodyText}
          </div>
          <div className="d-flex justify-content-center mb-10">
            {isCurrentUserSubscription ? (
              <button
                className="btn btn-light btn-block"
                onClick={() => setShowConfirmation(true)}>
                {isCanceled ? 'Reactivate subscription' : 'Cancel subscription'}
              </button>
            ) : (
              <button
                className={`btn btn-primary btn-block ${
                  changeAllowed ? '' : 'disabled'
                }`}
                onClick={() =>
                  hasPendingChange
                    ? setShowConfirmation(true)
                    : setShowChange(true)
                }>
                {hasPendingChange
                  ? 'Cancel change'
                  : `Change to ${isMonthly ? 'monthly' : 'annual'}`}
              </button>
            )}
          </div>
          {!isMonthly && (
            <a
              className="position-absolute"
              style={{ transform: 'translateX(-50%)', bottom: '3px' }}
              href="https://www.reasonstudios.com/school">
              <small>{`I'm a student`}</small>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
