import Modal from 'react-bootstrap/Modal'
import { useEffect } from 'react'

type ChurnOfferModalEarlyProps = {
  show: boolean
  title: string
  body: string
  disclaimer: string
  acceptText: string
  rejectText: string

  onAccept: () => void
  onReject: () => void
  onClose: () => void
}

export const ChurnOfferModalEarly = ({
  show,
  title,
  body,
  disclaimer,
  acceptText,
  rejectText,
  onAccept,
  onReject,
  onClose,
}: ChurnOfferModalEarlyProps) => {
  function handleKeyDown(event: KeyboardEvent) {
    if (['Escape', 'Esc'].includes(event.key)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  })

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button type="button" className="close" onClick={() => onClose()}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: body }} />
      <Modal.Footer>
        <div className="p-2">
          <button
            className="btn btn-light btn-block"
            onClick={() => onReject()}>
            {rejectText}
          </button>
        </div>
        <div className="p-2">
          <button className="btn btn-primary light" onClick={() => onAccept()}>
            {acceptText}
          </button>
        </div>
      </Modal.Footer>
      <div style={{ fontSize: '0.65em' }}>
        <Modal.Footer dangerouslySetInnerHTML={{ __html: disclaimer }} />
      </div>
    </Modal>
  )
}
